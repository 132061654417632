import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearMessage, clearShow } from "../redux/globalMessageSlice";
import { motion } from "framer-motion";
const Message = () => {
  const errorMessage = useSelector((state) => state.globalMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage.show) {
      setTimeout(() => {
        dispatch(clearShow());
        setTimeout(() => {
          dispatch(clearMessage());
        }, 500);
      }, 3000);
    }
  }, [errorMessage.show]);

  return (
    <motion.div
      initial={{ x: "125%" }}
      animate={{ x: errorMessage.show ? 0 : "125%" }}
      exit={{ x: "125%" }}
      transition={{
        duration: 0.4,
        ease: "easeInOut",
        stiffness: 100,
        damping: 20,
      }}
      className={
        " px-8 fixed bottom-8 right-4 z-50 py-2 font-semibold drop-shadow-lg rounded-md " +
        (errorMessage.type === "error"
          ? "text-white bg-danger"
          : "text-white bg-primary")
      }
    >
      {errorMessage.message}
    </motion.div>
  );
};

export default Message;
