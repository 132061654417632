import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sounds: JSON.parse(localStorage.getItem("playlist")) || [],
  justAdded: false,
};

export const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    setSounds: (state, action) => {
      state.sounds = action.payload;
      localStorage.setItem("playlist", JSON.stringify(action.payload));
    },
    addSound: (state, action) => {
      state.sounds.push(action.payload);
      state.justAdded = true;
      localStorage.setItem("playlist", JSON.stringify(state.sounds));
    },
    removeSound: (state, action) => {
      state.sounds = state.sounds.filter((sound) => sound !== action.payload);
      localStorage.setItem("playlist", JSON.stringify(state.sounds));
    },
    clearSounds: (state) => {
      state.sounds = [];
      localStorage.setItem("playlist", JSON.stringify([]));
    },
    disableJustAdded: (state) => {
      state.justAdded = false;
    },
  },
});

export const {
  setSounds,
  addSound,
  removeSound,
  clearSounds,
  disableJustAdded,
} = playlistSlice.actions;

export default playlistSlice.reducer;
