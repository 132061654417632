import React, { useEffect, useState } from "react";
import { IoIosPlay } from "react-icons/io";
import { MdOutlinePause } from "react-icons/md";
import { Link } from "react-router-dom";
import { RxDownload } from "react-icons/rx";
import { TailSpin } from "react-loader-spinner";
import { MdOutlineDownloadDone } from "react-icons/md";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { api_url, media_url } from "../constants";
import { useLocation } from "react-router-dom";
import { CgPlayListAdd } from "react-icons/cg";
import { CgPlayListCheck } from "react-icons/cg";
import { addSound, removeSound } from "../redux/playlistSlice";
import { showMessage } from "../redux/hintSlice";
const SoundEffectCard = ({ index, sound, setSounds }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedSuccessfully, setDownloadedSuccessfully] = useState(false);
  const settings = useSelector((state) => state.settings);
  const playlist = useSelector((state) => state.playlist);
  const [isLoading, setIsLoading] = useState(false);
  const [addedToPlaylist, setAddedToPlaylist] = useState(false);

  const dispatch = useDispatch();

  async function playAudio() {
    const isFirstTimePlaying = localStorage.getItem("isFirstTimePlaying");
    if (!isFirstTimePlaying) {
      dispatch(showMessage("Use the settings button to control the volume!"));
      localStorage.setItem("isFirstTimePlaying", true);
    }
    setSounds((prevSounds) => {
      return prevSounds.map((prevSound, i) => {
        if (i === index) {
          return { ...prevSound, isPlaying: true };
        } else {
          return { ...prevSound, isPlaying: false };
        }
      });
    });
    axios
      .post(api_url + `/sounds/${sound.id}/statistic/`, { play: true })
      .then((res) => {})
      .catch((err) => {});
  }

  async function pauseAudio() {
    setSounds((prevSounds) => {
      return prevSounds.map((prevSound, i) => {
        if (i === index) {
          return { ...prevSound, isPlaying: false };
        } else {
          return prevSound;
        }
      });
    });
  }

  useEffect(() => {
    sound.audioObject.preload = "auto";
    sound.audioObject.load();
    sound.audioObject.volume = settings.volume / 100;

    sound.audioObject.onended = () => {
      setSounds((prevSounds) => {
        return prevSounds.map((prevSound, i) => {
          if (i === index) {
            return { ...prevSound, isPlaying: false };
          } else {
            return prevSound;
          }
        });
      });
    };

    setAddedToPlaylist(playlist.sounds.some((s) => s === sound.id));

    return () => {
      sound.audioObject.pause();
    };
  }, [sound]);

  useEffect(() => {
    if (sound.isPlaying) {
      setIsLoading(true);
      const playPromise = sound.audioObject.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      const pausePromise = sound.audioObject.pause();
      if (pausePromise !== undefined) {
        pausePromise
          .then(() => {
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [sound.isPlaying]);

  useEffect(() => {
    sound.audioObject.volume = settings.volume / 100;
  }, [settings.volume]);

  return (
    <div
      key={sound.id}
      className="flex gap-x-3 w-full sm:grid sm:grid-cols-2 rounded-full items-center px-4 sm:py-2 sm:rounded-md py-1.5 gap-y-2 bg-background"
    >
      <button
        onClick={isLoading ? null : sound.isPlaying ? pauseAudio : playAudio}
        className="rounded-full border-solid border-primary sm:order-2 border-2 w-12 h-12 p-2 relative"
      >
        {isLoading ? (
          <TailSpin height={30} width={30} color="#0063FF" />
        ) : sound.isPlaying ? (
          <MdOutlinePause className="text-3xl absolute-center text-primary " />
        ) : (
          <IoIosPlay className="text-4xl absolute-center text-primary pl-1" />
        )}
      </button>
      <div className="flex grow flex-col text-secondary sm:order-1 sm:col-span-2 sm:w-full">
        <p className="text-lg font-medium sm:text-base">{sound.name}</p>
        <p className="text-tertiary text-sm">
          {sound.pack.map((pack, index) => (
            <span key={pack.id}>
              <Link
                to={"/packs/" + pack.slug}
                className="hover:underline underline-offset-4"
              >
                {pack.name}
              </Link>
              {index !== sound.pack.length - 1 && ", "}
            </span>
          ))}
        </p>
      </div>
      <div className="order-3 flex gap-x-2 sm:justify-end">
        <button
          onClick={() => {
            if (playlist.sounds.some((s) => s === sound.id)) {
              dispatch(removeSound(sound.id));
              setAddedToPlaylist(false);
            } else {
              setAddedToPlaylist(true);
              dispatch(addSound(sound.id));
              const isFirstSoundAdded =
                localStorage.getItem("isFirstSoundAdded");
              if (!isFirstSoundAdded) {
                dispatch(showMessage("The playlist is saved locally!"));
                localStorage.setItem("isFirstSoundAdded", true);
              }
            }
          }}
          className="mt-1"
        >
          {addedToPlaylist ? (
            <CgPlayListCheck className="text-3xl text-primary" />
          ) : (
            <CgPlayListAdd className="text-3xl text-primary" />
          )}
        </button>
        <button
          onClick={() => {
            setIsDownloading(true);
            setDownloadedSuccessfully(false);
            const url = media_url + sound.audio;
            axios({
              url: url,
              method: "GET",
              responseType: "blob",
            }).then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", sound.name + ".mp3");
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false);
              setDownloadedSuccessfully(true);
            });
            axios
              .post(api_url + `/sounds/${sound.id}/statistic/` , {
                download: true,
              })
              .then((res) => {})
              .catch((err) => {});
          }}
          className="px-1"
        >
          {downloadedSuccessfully ? (
            <MdOutlineDownloadDone className="text-2xl text-primary" />
          ) : isDownloading ? (
            <TailSpin height={30} width={30} color="#0063FF" />
          ) : (
            <RxDownload className="text-2xl text-primary" />
          )}
        </button>
      </div>
    </div>
  );
};

export default SoundEffectCard;
