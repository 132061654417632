import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { api_url } from "../constants";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet-async";
import { showMessage } from "../redux/hintSlice";

const SoundPacks = () => {
  const [packs, setPacks] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get(api_url + "/packs/")
      .then((res) => {
        setPacks(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    const isFirstSoundsPackVisited = localStorage.getItem(
      "isFirstSoundsPackVisited"
    );
    if (!isFirstSoundsPackVisited) {
      dispatch(
        showMessage(
          "In case you don't find the sound you are looking for, you can request it!"
        )
      );
      localStorage.setItem("isFirstSoundsPackVisited", true);
    }
  }, []);

  return (
    <div className="px-100 xl:px-80 lg:px-52 md:px-20 sm:px-8">
      <Helmet>
        <title>desoundfx.com - Sound Packs</title>
        <meta
          name="description"
          content="Browse through our collection of sound packs. Each pack contains a set of sounds that are related to each other."
        />
      </Helmet>
      <h1 className="text-4xl font-bold text-secondary mt-16 sm:text-3xl">
        Sound Packs
      </h1>
      <p className="text-tertiary mt-2">
        Browse through our collection of sound packs. Each pack contains a set
        of sounds that are related to each other. Currently we have{" "}
        {isLoading ? (
          <Skeleton
            highlightColor={"#0063FF1A"}
            inline={true}
            width={125}
            baseColor={"#EEF1FB"}
          />
        ) : (
          <span className="text-primary font-semibold">
            {packs.length} sound packs
          </span>
        )}{" "}
        available.
      </p>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="grid grid-cols-4 gap-8 mt-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 md:gap-6 xl:grid-cols-3"
      >
        {isLoading
          ? Array.from({ length: 7 }).map((_, index) => (
              <div
                key={index}
                className="bg-white rounded-lg p-4 border-primary/25 relative
            group border-solid border-2"
              >
                <Skeleton
                  height={64}
                  width={96}
                  baseColor={"#EEF1FB"}
                  highlightColor={"#0063FF1A"}
                  className="relative z-10"
                />
                <Skeleton
                  baseColor={"#EEF1FB"}
                  highlightColor={"#0063FF1A"}
                  height={16}
                  width={"100%"}
                  className="mt-2 relative z-10"
                />
              </div>
            ))
          : packs.map((pack, index) => {
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2, delay: index * 0.1 }}
                  key={pack.id}
                >
                  <Link
                    to={`/packs/${pack.slug}`}
                    key={pack.id}
                    className="bg-white rounded-lg p-4 block border-primary/50 relative group border-solid border-2"
                  >
                    <h2 className="text-lg font-semibold mt-4 z-10 relative">
                      {pack.name}
                    </h2>
                    <p className="text-tertiary mt-2 z-10 relative">
                      {pack.sounds_count} sounds
                    </p>
                    <div className="absolute top-0 bottom-0 group-hover:w-full ease-in-out duration-[400ms] transition-all rounded-lg bg-background left-0 w-0"></div>
                  </Link>
                </motion.div>
              );
            })}
      </motion.div>
    </div>
  );
};

export default SoundPacks;
