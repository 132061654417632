import { FaInstagram } from "react-icons/fa";
import { SlSocialYoutube } from "react-icons/sl";

const debug = false;
const base_url = debug ? "http://localhost:8000" : "";
export const api_url = base_url + "/api";
export const media_url = base_url + (debug ? "" : "/media");
export const auth_url = base_url + "/auth";
export const jwt_url = base_url + "/jwt";

export const socialLinks = [
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/desoundfx_com/",
  },
  {
    icon: <SlSocialYoutube />,
    url: "https://www.youtube.com/@desoundfx",
  },
];

export const faqs = [
  {
    question: "What is desoundfx.com?",
    opened: false,
    answer:
      "desoundfx.com is a website that offers a wide range of sound effects for your projects. You can search for sound effects by name or category and download them for free.",
  },
  {
    question: "How can I download a sound effect?",
    opened: false,

    answer:
      "To download a sound effect, click on the download button next to the sound effect. The sound effect will be downloaded in MP3 format.",
  },
  {
    question: "Can I use the sound effects for commercial projects?",
    opened: false,

    answer:
      "Yes, you can use the sound effects for commercial projects. However, you are not allowed to sell or redistribute the sound effects.",
  },
  {
    question: "Can I modify the sound effects?",
    opened: false,

    answer:
      "Yes, you can modify the sound effects to suit your project. However, you are not allowed to sell or redistribute the modified sound effects.",
  },
  {
    question: "How can I contact the desoundfx.com team?",
    opened: false,

    answer:
      "You can contact the desoundfx.com team by sending an email to scriptifycontact@gmail.com.",
  },
];
