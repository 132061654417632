import React from "react";
import { Puff } from "react-loader-spinner";

const CenterLoading = () => {
  return (
    <div className="absolute-center top-1/3">
      <Puff height={64} width={164} />
    </div>
  );
};

export default CenterLoading;
