import { createSlice } from "@reduxjs/toolkit";

const initialState = "Admin Panel";

export const adminPageNameSlice = createSlice({
  name: "adminPageName",
  initialState,
  reducers: {
    changeAdminPageName: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { changeAdminPageName } = adminPageNameSlice.actions;
export default adminPageNameSlice.reducer;
