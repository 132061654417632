import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SoundEffectCard from "../components/SoundEffectCard";
import notFoundImage from "../static/images/not-found.svg";
import { Helmet } from "react-helmet-async";
const SearchSounds = () => {
  const [sounds, setSounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSelector((state) => state.settings);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!query || query === "") {
      setNotFound(true);
      return;
    }

    axios
      .get(api_url + "/sounds/search/?q=" + query)
      .then((res) => {
        const soundsData = res.data;
        soundsData.forEach((sound) => {
          sound.audioObject = new Audio(media_url + sound.audio);
        });
        setSounds(soundsData);
        setIsLoading(false);
        if (soundsData.length === 0) {
          setNotFound(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        setNotFound(true);
      });
  }, [query]);

  return (
    <div className="px-100  xl:px-80 lg:px-52 md:px-20 sm:px-8 ">
      <Helmet>
        <title>desoundfx.com - Search Sounds</title>
        <meta
          name="description"
          content="Search through our collection of sound effects. We have a wide range of sound effects that you can use in your projects."
        />
      </Helmet>
      <h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="text-4xl font-bold text-secondary mt-16 w-fit min-w-[50%] sm:text-3xl"
      >
        Searching for "{query}"
      </h1>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className=" mt-2"
      >
        <p className="text-tertiary">
          Browse through our collection of sound effects. We found exactly{" "}
          {isLoading ? (
            <Skeleton
              highlightColor={"#0063FF1A"}
              inline={true}
              width={100}
              baseColor={"#EEF1FB"}
            />
          ) : (
            <span className="text-primary font-semibold">
              {sounds.length} sound effects
            </span>
          )}{" "}
          related to your search query.
        </p>
      </motion.div>
      {notFound && (
        <motion.img
          initial={{ opacity: 0, x: -22 }}
          animate={{ opacity: 1, x: -16 }}
          src={notFoundImage}
          alt="Not Found"
          className="w-32 opacity-50 mt-8"
        />
      )}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col gap-y-4 mt-6"
      >
        {isLoading ? (
          <Skeleton
            count={6}
            height={55}
            borderRadius={999}
            baseColor={"#EEF1FB"}
            highlightColor={"#0063FF1A"}
          />
        ) : (
          sounds.map((sound, index) => {
            return (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                key={sound.id}
              >
                <SoundEffectCard
                  setSounds={setSounds}
                  key={sound.id}
                  sounds={sounds}
                  index={index}
                  sound={sound}
                />
              </motion.span>
            );
          })
        )}
      </motion.div>
    </div>
  );
};

export default SearchSounds;
