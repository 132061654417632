import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import {
  setGlobalErrorMessage,
  setGlobalNotificationMessage,
} from "../redux/globalMessageSlice";
import { useDispatch } from "react-redux";
import CenterLoading from "../components/CenterLoading";
import { Link } from "react-router-dom";
import { RxDownload } from "react-icons/rx";
import AdminSoundCard from "../components/AdminSoundCard";

const AdminSounds = () => {
  const [sounds, setSounds] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  useEffect(() => {
    axios
      .get(api_url + "/sounds/")
      .then((res) => {
        setSounds(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          setGlobalErrorMessage("Failed to fetch sounds from the database")
        );
      });
  }, []);

  function AddSoundHandler(e) {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    if (!name) {
      dispatch(setGlobalErrorMessage("Please enter a sound name"));
      return;
    }
    if (!file) {
      dispatch(setGlobalErrorMessage("Please select a sound file"));
      return;
    }
    setIsAdding(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);
    axios
      .post(api_url + "/sounds/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(setGlobalNotificationMessage("Sound added successfully"));
        setOpenForm(!openForm);
        form.reset();
        setIsAdding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsAdding(false);
        dispatch(setGlobalErrorMessage("Failed to add sound to the database"));
      });
  }

  return (
    <div>
      <div className="flex flex-col gap-y-4">
        {!openForm && (
          <button
            onClick={() => setOpenForm(!openForm)}
            className="custom-button"
          >
            Add new pack
          </button>
        )}
        {openForm && (
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="flex flex-col gap-y-4"
            onSubmit={AddSoundHandler}
            id="addSoundForm"
          >
            <input
              type="text"
              placeholder="Sound name"
              className="custom-input"
              name="name"
            />
            <label
              htmlFor="sound-file"
              className="custom-button  cursor-pointer text-center w-fit"
            >
              {file && file.name ? file.name : "Choose a sound file"}
            </label>
            <input
              type="file"
              id="sound-file"
              name="file"
              className="hidden"
              accept="audio/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) {
                  return;
                }
                if (file.size > 100000000) {
                  dispatch(
                    setGlobalErrorMessage("File size should be less than 100MB")
                  );
                  return;
                }

                const type = file.type.split("/")[0];
                if (type !== "audio") {
                  dispatch(
                    setGlobalErrorMessage("Please select an audio file")
                  );
                  return;
                }

                setFile(e.target.files[0]);
              }}
            />

            <div className="grid grid-cols-2 gap-x-8">
              <button
                disabled={isAdding}
                className="custom-button bg-danger"
                onClick={() => {
                  setOpenForm(!openForm);
                  document.getElementById("addSoundForm").reset();
                }}
                type="button"
              >
                Cancel
              </button>
              <button disabled={isAdding} className="custom-button">
                {isAdding ? "Adding sound..." : "Add sound"}
              </button>
            </div>
          </motion.form>
        )}
      </div>
      <div className="flex flex-col gap-y-4 relative mt-4">
        {isLoading ? (
          <CenterLoading />
        ) : (
          sounds.map((sound, index) => (
            <AdminSoundCard sound={sound} index={index} key={index} />
          ))
        )}
      </div>
    </div>
  );
};

export default AdminSounds;
