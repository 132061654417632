import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { api_url, media_url } from "../constants";
import SoundEffectCard from "../components/SoundEffectCard";

import axios from "axios";
import { Helmet } from "react-helmet-async";

const TrendingSounds = () => {
  const [sounds, setSounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(api_url + "/sounds/trending/")
      .then((res) => {
        const soundsData = res.data;
        soundsData.forEach((sound) => {
          sound.audioObject = new Audio(media_url + sound.audio);
        });
        setSounds(soundsData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="px-100  xl:px-80 lg:px-52 md:px-20 sm:px-8 ">
      <Helmet>
        <title>desoundfx.com - Trending Sounds</title>
        <meta
          name="description"
          content="Browse through our collection of trending sound effects. These are the most popular sound effects on our platform."
        />
      </Helmet>
      <h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="text-4xl font-bold text-secondary mt-16 w-fit min-w-[50%] sm:text-3xl"
      >
        Trending Sounds
      </h1>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className=" mt-2"
      >
        <p className="text-tertiary">
          Browse through our collection of trending sound effects. These are the
          most popular sound effects on our platform. There are more than{" "}
          {isLoading ? (
            <Skeleton
              highlightColor={"#0063FF1A"}
              inline={true}
              width={100}
              baseColor={"#EEF1FB"}
            />
          ) : (
            <span className="text-primary font-semibold">
              {sounds.length} sound effects
            </span>
          )}{" "}
          in trending right now.
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col gap-y-4 mt-6"
      >
        {isLoading ? (
          <Skeleton
            count={6}
            height={55}
            borderRadius={999}
            baseColor={"#EEF1FB"}
            highlightColor={"#0063FF1A"}
          />
        ) : (
          sounds.map((sound, index) => {
            return (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                key={sound.id}
              >
                <SoundEffectCard
                  setSounds={setSounds}
                  key={sound.id}
                  sounds={sounds}
                  index={index}
                  sound={sound}
                />
              </motion.span>
            );
          })
        )}
      </motion.div>
    </div>
  );
};

export default TrendingSounds;
