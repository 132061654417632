import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  show: false,
};

export const hintSlice = createSlice({
  name: "hint",
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload;
      state.show = true;
    },
    hideMessage: (state) => {
      state.show = false;
    },
  },
});

export const { showMessage, hideMessage } = hintSlice.actions;

export default hintSlice.reducer;
