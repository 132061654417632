import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newsLetterModal: false,
  informationModal: false,
  packRequestModal: false,
};

export const modalsOpenerSlice = createSlice({
  name: "modalsOpener",
  initialState,
  reducers: {
    openNewsLetterModal: (state) => {
      state.newsLetterModal = true;
    },
    closeNewsLetterModal: (state) => {
      state.newsLetterModal = false;
    },
    openInformationModal: (state) => {
      state.informationModal = true;
    },
    closeInformationModal: (state) => {
      state.informationModal = false;
    },
    openPackRequestModal: (state) => {
      state.packRequestModal = true;
    },
    closePackRequestModal: (state) => {
      state.packRequestModal = false;
    },
  },
});

export const {
  openNewsLetterModal,
  closeNewsLetterModal,
  openInformationModal,
  closeInformationModal,
  openPackRequestModal,
  closePackRequestModal,

} = modalsOpenerSlice.actions;

export default modalsOpenerSlice.reducer;
