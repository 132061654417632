import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { RiPlayList2Fill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { disableJustAdded } from "../redux/playlistSlice";
import { hideMessage } from "../redux/hintSlice";
import { openPackRequestModal } from "../redux/modalsOpenerSlice";
const UserLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const playlist = useSelector((state) => state.playlist);
  const hint = useSelector((state) => state.hint);
  const dispatch = useDispatch();
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    if (playlist.justAdded) {
      setTimeout(
        () => {
          dispatch(disableJustAdded());
        },
        isScrolledDown ? 1000 : 400
      );
    }
  }, [playlist.justAdded]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (hint.show) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 4000);
    }
  }, [hint.show]);

  return (
    <div className=" flex flex-col min-h-screen relative">
      <header className="py-5 px-12 flex md:flex-col gap-y-3 items-center justify-between bg-white shadow">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="absolute top-5 left-5 sm:block hidden"
        >
          <RxHamburgerMenu className="text-3xl text-secondary" />
        </button>
        <Link to="/" className="w-fit block sm:mx-auto">
          <div className="text-3xl font-bold flex">
            <span className="text-secondary font-midpoint">desoundfx</span>
            <span className="text-primary font-midpoint">.com</span>
          </div>
        </Link>
        <nav className="text-tertiary sm:hidden w-fit flex items-center justify-center gap-x-10">
          <Link to="/all-packs">All sound packs</Link>
          <Link to="/trending">Trending sounds</Link>
          <Link to="/new">New sounds</Link>
          <button
            onClick={() => dispatch(openPackRequestModal())}
            className="border-2 border-primary text-primary font-medium rounded-full px-4 py-0.5 border-solid"
          >
            Request sounds
          </button>
          <Link to="/playlist" className="relative">
            <RiPlayList2Fill className="text-2xl" />
            {playlist.sounds.length > 0 && (
              <motion.span
                initial={{ scale: 0, translateX: 8, translateY: -8 }}
                animate={{ scale: playlist.justAdded ? 1.1 : 1 }}
                className="absolute top-0 right-0 bg-primary text-white rounded-full p-2 text-xs w-5 h-5 flex items-center justify-center "
              >
                {playlist.sounds.length}
              </motion.span>
            )}
          </Link>
        </nav>
      </header>
      <div className="pb-20">
        <Outlet />
      </div>
      <p className="py-6 text-tertiary text-center mt-auto">
        © 2024 desoundfx.com All rights reserved.
      </p>
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: isMenuOpen ? 0 : "-100%" }}
        transition={{ duration: 0.2 }}
        className="absolute top-0 left-0 h-full w-full bg-white  z-10"
      >
        <button
          className="absolute top-5 right-5"
          onClick={() => setIsMenuOpen(false)}
        >
          <IoMdClose className="text-2xl text-primary" />
        </button>
        <nav className="flex flex-col gap-y-6 p-8 text-xl text-center  h-fit  mt-40">
          <Link to="/" className="w-fit block sm:mx-auto">
            <div className="text-3xl font-bold flex">
              <span className="text-secondary font-midpoint">desoundfx</span>
              <span className="text-primary font-midpoint">.com</span>
            </div>
          </Link>
          <div></div>
          <span onClick={() => setIsMenuOpen(false)}>
            <Link to="/all-packs">All sound packs</Link>
          </span>
          <span onClick={() => setIsMenuOpen(false)}>
            <Link to="/trending">Trending sounds</Link>
          </span>
          <span onClick={() => setIsMenuOpen(false)}>
            <Link to="/new">New sounds</Link>
          </span>
          <button
            onClick={() => {
              dispatch(openPackRequestModal());
              setIsMenuOpen(false);
            }}
            className="border-2 w-fit mx-auto border-primary text-primary font-medium rounded-full px-4 py-0.5 border-solid"
          >
            Request sounds
          </button>
          <span onClick={() => setIsMenuOpen(false)}>
            <Link
              to="/playlist"
              className="relative flex gap-x-2 w-fit mx-auto"
            >
              My Playlist
              {playlist.sounds.length > 0 && (
                <motion.span
                  initial={{ scale: 0, translateX: 14, translateY: -6 }}
                  animate={{ scale: playlist.justAdded ? 1.1 : 1 }}
                  className="absolute top-0 right-0 bg-primary text-white rounded-full p-2 text-xs w-5 h-5 flex items-center justify-center "
                >
                  {playlist.sounds.length}
                </motion.span>
              )}
            </Link>
          </span>
        </nav>
      </motion.div>
      <motion.div
        initial={{ translateY: -100 }}
        animate={{
          translateY: isScrolledDown && playlist.justAdded ? 0 : -100,
        }}
        transition={{ duration: 0.3 }}
        className="fixed top-0 right-5 bg-primary drop-shadow-md font-semibold rounded-b-full flex justify-center items-center text-white h-10 w-8"
      >
        +1
      </motion.div>
      <motion.div
        initial={{ translateY: -110, translateX: "-50%" }}
        animate={{
          translateY: hint.show ? 15 : -110,
        }}
        className="bg-white drop-shadow-md rounded-md flex flex-col gap-y-2 w-80 sm:w-full p-4 fixed top-0 left-1/2"
      >
        <h3 className="text-primary font-semibold text-lg text-center uppercase">
          Hint
        </h3>
        <p className="text-tertiary text-sm text-center font-medium">
          {hint.message}
        </p>
      </motion.div>
    </div>
  );
};

export default UserLayout;
