import React, { useState, useEffect } from "react";
import axios from "axios";
import { api_url, media_url } from "../constants";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import SoundEffectCard from "../components/SoundEffectCard";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { CgPlayListAdd } from "react-icons/cg";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import { RxDownload } from "react-icons/rx";
import { TailSpin } from "react-loader-spinner";
import { MdOutlineDownloadDone } from "react-icons/md";
import { PiTrash } from "react-icons/pi";
import { clearSounds } from "../redux/playlistSlice";

const Playlist = () => {
  const playlist = useSelector((state) => state.playlist);
  const [suggestedSounds, setSuggestedSounds] = useState([]);
  const dispatch = useDispatch();
  const [sounds, setSounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [areSuggestionsLoading, setAreSuggestionsLoading] = useState(false);
  const [isSuggestionsButtonDissapearing, setIsSuggestionsButtonDissapearing] =
    useState(false);
  useEffect(() => {
    axios
      .post(api_url + "/sounds/playlist/", { ids: playlist.sounds })
      .then((res) => {
        const soundsData = res.data;
        soundsData.forEach((sound) => {
          sound.audioObject = new Audio(media_url + sound.audio);
        });
        setSounds(soundsData);
        setIsLoading(false);
        console.log(soundsData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function GetSuggestionsHandler() {
    setIsSuggestionsButtonDissapearing(true);
    setTimeout(() => {
      setAreSuggestionsLoading(true);

      axios
        .post(api_url + "/sounds/suggested/", { ids: playlist.sounds })
        .then((res) => {
          const soundsData = res.data;
          soundsData.forEach((sound) => {
            sound.audioObject = new Audio(media_url + sound.audio);
          });
          setSuggestedSounds(soundsData);
          setAreSuggestionsLoading(false);
          setShowSuggestions(true);
        })
        .catch((err) => {
          setIsSuggestionsButtonDissapearing(false);

          setAreSuggestionsLoading(false);
          dispatch(
            setGlobalErrorMessage(
              "Failed to get suggestions. Please try again later."
            )
          );
        });
    }, 200);
  }

  const [isPlaylistDownloading, setIsPlaylistDownloading] = useState(false);
  const [isPlaylistDownloaded, setIsPlaylistDownloaded] = useState(false);
  const [isPlaylistFailedToDownload, setIsPlaylistFailedToDownload] =
    useState(false);
  function DownloadPlaylistHandler() {
    setIsPlaylistDownloading(true);
    axios
      .post(api_url + "/sounds/playlist/download/", { ids: playlist.sounds })
      .then((res) => {
        setIsPlaylistDownloading(false);
        setIsPlaylistDownloaded(true);
        const zipFile = res.data;
        const link = document.createElement("a");
        link.href = `data:application/zip;base64,${zipFile}`;
        link.download = "playlist_" + playlist.sounds.length + "_sounds.zip";
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        setIsPlaylistFailedToDownload(true);
        setIsPlaylistDownloading(false);
        dispatch(
          setGlobalErrorMessage(
            "Failed to download your playlist. Please try again later."
          )
        );
      });
  }
  const [isRemovingSounds, setIsRemovingSounds] = useState(false);

  return (
    <div className="px-100  xl:px-80 lg:px-52 md:px-20 sm:px-8 ">
      <Helmet>
        <title>desoundfx.com - My Playlist</title>
        <meta
          name="description"
          content="Create your own playlist of sound effects and download them all at once. desoundfx.com is a website that offers a wide range of sound effects for your projects."
        />
      </Helmet>
      <h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="text-4xl flex font-bold items-end gap-x-2 text-secondary mt-16 w-fit min-w-[50%] sm:text-3xl"
      >
        Playlist Sounds
        {playlist.sounds.length > 0 && (
          <button
            disabled={isPlaylistDownloading || isPlaylistDownloaded}
            onClick={DownloadPlaylistHandler}
            className="pb-0.5"
          >
            {isPlaylistDownloading ? (
              <TailSpin color="#0063FF" width={"26"} height={"26"} />
            ) : isPlaylistDownloaded ? (
              <MdOutlineDownloadDone className="text-primary text-3xl" />
            ) : (
              <RxDownload className="text-primary pb-0.5 text-3xl" />
            )}
          </button>
        )}
        {playlist.sounds.length > 0 && (
          <button
            onClick={() => {
              setIsRemovingSounds(true);
              setTimeout(() => {
                setSounds([]);
                dispatch(clearSounds());
                setIsRemovingSounds(false);
              }, 200);
            }}
            className="pb-0.5"
          >
            <PiTrash className="text-primary text-3xl" />
          </button>
        )}
      </h1>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className=" mt-2"
      >
        <p className="text-tertiary">
          You can add sound effects to your playlist by clicking the{" "}
          <CgPlayListAdd className="inline text-primary text-lg" /> icon on the
          sound effect card. You have exactly{" "}
          {isLoading ? (
            <Skeleton
              highlightColor={"#0063FF1A"}
              inline={true}
              width={100}
              baseColor={"#EEF1FB"}
            />
          ) : (
            <span className="text-primary font-semibold">
              {sounds.length} sound effects
            </span>
          )}{" "}
          in your playlist right now.
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, rowGap: 16 }}
        animate={{ opacity: 1, rowGap: isRemovingSounds ? 0 : 16 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col mt-6"
      >
        {isLoading ? (
          <Skeleton
            count={4}
            height={55}
            borderRadius={999}
            baseColor={"#EEF1FB"}
            highlightColor={"#0063FF1A"}
          />
        ) : (
          sounds.map((sound, index) => {
            return (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, height: isRemovingSounds ? 0 : "auto" }}
                transition={{
                  duration: 0.2,
                  delay: isRemovingSounds ? 0 : index * 0.1,
                }}
                key={sound.id}
                className="overflow-y-hidden"
              >
                <SoundEffectCard
                  setSounds={setSounds}
                  key={sound.id}
                  sounds={sounds}
                  index={index}
                  sound={sound}
                />
              </motion.span>
            );
          })
        )}
        {areSuggestionsLoading ? (
          <Skeleton
            count={4}
            height={55}
            borderRadius={999}
            baseColor={"#EEF1FB"}
            highlightColor={"#0063FF1A"}
          />
        ) : (
          suggestedSounds.map((sound, index) => {
            return (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                key={sound.id}
              >
                <SoundEffectCard
                  setSounds={setSuggestedSounds}
                  key={sound.id}
                  sounds={suggestedSounds}
                  index={index}
                  sound={sound}
                />
              </motion.span>
            );
          })
        )}
      </motion.div>
      <motion.button
        disabled={isSuggestionsButtonDissapearing}
        initial={{
          opacity: 0,
          translateY:
            !isLoading && sounds.length == 0 && suggestedSounds.length == 0
              ? -16
              : 16,
        }}
        animate={{
          opacity: isSuggestionsButtonDissapearing || isLoading ? 0 : 1,
          translateY:
            !isLoading && sounds.length == 0 && suggestedSounds.length == 0
              ? -16
              : 16,
        }}
        onClick={GetSuggestionsHandler}
        transition={{ duration: 0.2 }}
        className={"custom-button sm:w-full"}
      >
        Show Suggestions
      </motion.button>
    </div>
  );
};

export default Playlist;
