import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import { useParams, useNavigate } from "react-router-dom";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import CenterLoading from "../components/CenterLoading";
import AdminSoundCard from "../components/AdminSoundCard";
import AdminPackSoundCard from "../components/AdminPackSoundCard";

const AdminPack = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pack, setPack] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sounds, setSounds] = useState([]);
  const [soundsLoading, setSoundsLoading] = useState(true);
  useEffect(() => {
    axios
      .get(api_url + "/packs/" + id + "/")
      .then((res) => {
        setPack(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setGlobalErrorMessage("Failed to fetch pack from the database");
      });

    axios
      .get(api_url + "/sounds/")
      .then((res) => {
        setSounds(res.data);
        setSoundsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        setSoundsLoading(false);
        setGlobalErrorMessage("Failed to fetch sounds from the database");
      });
  }, [id]);

  return isLoading ? (
    <CenterLoading />
  ) : (
    <div className="">
      <div className="grid grid-cols-2 gap-x-10">
        <div className="flex flex-col gap-y-2">
          <h1 className="text-2xl font-bold">{pack.name}</h1>
          <p>{pack.description}</p>
          <h2 className="text-xl font-semibold mt-4">Pack Sounds</h2>
          <div className="flex flex-col gap-y-2">
            {sounds
              .filter((sound) => sound.pack.includes(Number(id)))
              .map((sound, index) => (
                <AdminPackSoundCard
                  sound={sound}
                  index={index}
                  pack={Number(id)}
                  setSounds={setSounds}
                />
              ))}
          </div>
        </div>
        <div className="flex relative flex-col gap-y-2">
          <h1 className="text-2xl font-bold">Available Sounds</h1>
          {soundsLoading ? (
            <CenterLoading />
          ) : (
            <div className="">
              {sounds
                .filter((sound) => !sound.pack.includes(Number(id)))
                .map((sound, index) => (
                  <AdminPackSoundCard
                    sound={sound}
                    index={index}
                    pack={Number(id)}
                    setSounds={setSounds}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPack;
