import { configureStore } from "@reduxjs/toolkit";
import adminPageNameReducer from "./adminPageNameSlice";
import languageReducer from "./languageSlice";
import globalMessageReducer from "./globalMessageSlice";
import settingsReducer from "./settingsSlice";
import modalsOpenerReducer from "./modalsOpenerSlice";
import playlistReducer from "./playlistSlice";
import hintReducer from "./hintSlice";

export const store = configureStore({
  reducer: {
    adminPageName: adminPageNameReducer,
    globalMessage: globalMessageReducer,
    language: languageReducer,
    settings: settingsReducer,
    modalsOpener: modalsOpenerReducer,
    playlist: playlistReducer,
    hint: hintReducer,
  },
});
