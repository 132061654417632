import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { api_url } from "../constants";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import CenterLoading from "../components/CenterLoading";
import { MdWorkOutline } from "react-icons/md";
import { LuNetwork } from "react-icons/lu";
import { MdOutlineReviews } from "react-icons/md";
import { BsJournalAlbum } from "react-icons/bs";
import { AiOutlineSound } from "react-icons/ai";
import { LuLineChart } from "react-icons/lu";

import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";

const AdminLayout = () => {
  const pageName = useSelector((state) => state.adminPageName);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === "/admin") {
      navigate("/admin/packs");
    }
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  function fetchAccess(code) {
    setIsLoading(true);
    axios
      .post(`${api_url}/verify/`, { code })
      .then((res) => {
        setIsUserSignedIn(true);
        localStorage.setItem("code", code);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsUserSignedIn(false);
        localStorage.removeItem("code");
        setIsLoading(false);
        dispatch(setGlobalErrorMessage("Codul de acces este incorect"));
      });
  }

  useEffect(() => {
    const code = localStorage.getItem("code");
    if (code) {
      fetchAccess(code);
    }
  }, []);

  return isLoading ? (
    <CenterLoading />
  ) : !isUserSignedIn ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        fetchAccess(e.target.code.value);
      }}
      className="flex flex-col w-[20rem] absolute top-1/2 left-1/2 -translate-x-1/2 gap-y-4 -translate-y-1/2"
    >
      <input
        type="text"
        placeholder="Cod de acces"
        className="custom-input"
        name="code"
      />
      <button className="w-full font-semibold px-4 rounded py-2 bg-primary text-white">
        Verifică
      </button>
    </form>
  ) : (
    <div className="flex">
      <div
        className={
          "w-60 py-8 max-h-[100vh] bg-secondary transition-transform ease-in duration-200 z-50 sm:h-screen md:h-screen md:w-full sm:w-full md:fixed sm:fixed " +
          (showMenu
            ? "sm:translate-x-0 md:translate-x-0"
            : "sm:-translate-x-full md:-translate-x-full")
        }
      >
        <IoMdClose
          onClick={() => setShowMenu(false)}
          className="text-3xl text-white absolute top-6 right-6 cursor-pointer sm:block md:block hidden"
        />
        <h1 className="text-white font-semibold text-center text-2xl">
          Admin Panel
        </h1>
        <ul className="text-white flex flex-col mt-6 text-lg">
          <span onClick={() => setShowMenu(false)}>
            <Link
              to={"/admin/packs"}
              className={
                "w-fit py-2 px-4  flex items-center gap-x-3" +
                (location.pathname.includes("/admin/packs")
                  ? " font-semibold"
                  : " font-medium")
              }
            >
              <BsJournalAlbum className="text-white" />
              Sound Packs
            </Link>
          </span>
          <span onClick={() => setShowMenu(false)}>
            <Link
              to={"/admin/sounds"}
              className={
                "w-fit py-2 px-4  flex items-center gap-x-3" +
                (location.pathname.includes("/admin/sounds")
                  ? " font-semibold"
                  : " font-medium")
              }
            >
              <AiOutlineSound className="text-white" />
              Sounds
            </Link>
          </span>
          <span onClick={() => setShowMenu(false)}>
            <Link
              to={"/admin/statistics"}
              className={
                "w-fit py-2 px-4  flex items-center gap-x-3" +
                (location.pathname.includes("/admin/statistics")
                  ? " font-semibold"
                  : " font-medium")
              }
            >
              <LuLineChart className="text-white" />
              Statistics
            </Link>
          </span>
        </ul>
      </div>
      <div className="w-[calc(100%-15rem)] h-screen sm:w-full md:w-full">
        <div className="w-full h-10 bg-secondary flex gap-x-6 items-center sm:py-4 px-6 text-white uppercase font-semibold text-lg">
          <RxHamburgerMenu
            className="text-white text-2xl cursor-pointer"
            onClick={() => setShowMenu(true)}
          />
          {pageName}
        </div>
        <div className="py-10 px-14 sm:px-8 sm:py-6 bg-background relative h-[calc(100vh-2.5rem)] overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
