import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { api_url } from "../constants";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import SoundEffectCard from "../components/SoundEffectCard";
import { media_url } from "../constants";
import { Helmet } from "react-helmet-async";

const Pack = () => {
  const [pack, setPack] = useState(null);
  const [sounds, setSounds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [soundsLoading, setSoundsLoading] = useState(true);
  const { slug } = useParams();
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    setIsLoading(true);
    setSoundsLoading(true);
    axios
      .get(api_url + "/packs/" + slug + "/")
      .then((res) => {
        setPack(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setGlobalErrorMessage("Failed to fetch pack from the database");
      });

    axios
      .get(api_url + "/packs/" + slug + "/sounds/")
      .then((res) => {
        const soundsData = res.data;
        soundsData.forEach((sound) => {
          sound.audioObject = new Audio(media_url + sound.audio);
        });
        setSounds(soundsData);
        setSoundsLoading(false);
      })
      .catch((err) => {
        setGlobalErrorMessage("Failed to fetch sounds from the database");
      });
  }, [slug]);

  return (
    <div className="px-100 xl:px-80 lg:px-52 md:px-20 sm:px-8">
      <Helmet>
        <title>
          desoundfx.com - {isLoading ? "Loading..." : pack.name + " Sound Pack"}
        </title>
        <meta
          name="description"
          content={
            isLoading
              ? "Loading..."
              : pack.description +
                " Currently we have " +
                sounds.length +
                " sound effects available in this pack. Choose from a wide range of sound effects for your projects."
          }
        />
      </Helmet>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="text-4xl font-bold text-secondary mt-16 w-fit min-w-[50%]  sm:text-3xl"
      >
        {isLoading ? (
          <Skeleton highlightColor={"#0063FF1A"} baseColor={"#EEF1FB"} />
        ) : (
          "Sound Pack " + pack.name
        )}
      </motion.h1>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className=" mt-2"
      >
        {isLoading ? (
          <Skeleton
            highlightColor={"#0063FF1A"}
            count={3}
            baseColor={"#EEF1FB"}
          />
        ) : (
          <p className="text-tertiary">
            {pack.description} Currently we have{" "}
            {soundsLoading ? (
              <Skeleton
                highlightColor={"#0063FF1A"}
                inline={true}
                width={100}
                baseColor={"#EEF1FB"}
              />
            ) : (
              <span className="text-primary font-semibold">
                {sounds.length} sound effects
              </span>
            )}{" "}
            available in this pack.
          </p>
        )}
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col gap-y-4 mt-6"
      >
        {soundsLoading ? (
          <Skeleton
            count={6}
            height={55}
            // circle={true}
            borderRadius={999}
            baseColor={"#EEF1FB"}
            highlightColor={"#0063FF1A"}
          />
        ) : (
          sounds.map((sound, index) => {
            return (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                key={sound.id}
              >
                <SoundEffectCard
                  setSounds={setSounds}
                  sounds={sounds}
                  index={index}
                  sound={sound}
                />
              </motion.span>
            );
          })
        )}
      </motion.div>
    </div>
  );
};

export default Pack;
