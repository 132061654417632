import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { api_url } from "../constants";
import {
  setGlobalErrorMessage,
  setGlobalNotificationMessage,
} from "../redux/globalMessageSlice";
import { useDispatch } from "react-redux";
import CenterLoading from "../components/CenterLoading";
import { Link } from "react-router-dom";

const AdminPacks = () => {
  const [packs, setPacks] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios
      .get(api_url + "/packs/")
      .then((res) => {
        setPacks(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        dispatch(
          setGlobalErrorMessage("Failed to fetch packs from the database")
        );
      });
  }, []);

  const dispatch = useDispatch();
  function AddPackHandler(e) {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const description = form.description.value;
    setIsAdding(true);
    axios
      .post(api_url + "/packs/", { name, description })
      .then((res) => {
        dispatch(setGlobalNotificationMessage("Pack added successfully"));
        setOpenForm(!openForm);
        form.reset();
        setIsAdding(false);
      })
      .catch((err) => {
        console.log(err);
        setIsAdding(false);
        dispatch(setGlobalErrorMessage("Failed to add pack to the database"));
      });
  }

  return (
    <div className="">
      <div className="flex flex-col gap-y-4">
        {!openForm && (
          <button
            onClick={() => setOpenForm(!openForm)}
            className="custom-button"
          >
            Add new pack
          </button>
        )}
        {openForm && (
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            className="flex flex-col gap-y-4"
            onSubmit={AddPackHandler}
            id="addPackForm"
          >
            <input
              type="text"
              placeholder="Package name"
              className="custom-input"
              name="name"
            />
            <textarea
              type="text"
              placeholder="Package description"
              className="custom-input"
              name="description"
            />
            <div className="grid grid-cols-2 gap-x-8">
              <button
                disabled={isAdding}
                className="custom-button bg-danger"
                onClick={() => {
                  setOpenForm(!openForm);
                  document.getElementById("addPackForm").reset();
                }}
                type="button"
              >
                Cancel
              </button>
              <button disabled={isAdding} className="custom-button">
                {isAdding ? "Adding pack..." : "Add pack"}
              </button>
            </div>
          </motion.form>
        )}
      </div>
      <div className="flex flex-col gap-y-4 relative mt-4">
        {isLoading ? (
          <CenterLoading />
        ) : (
          packs.map((pack) => (
            <Link
              to={`/admin/packs/${pack.id}`}
              key={pack.id}
              className="flex hover:scale-[100.5%] transition-transform ease-in-out duration-200 justify-between gap-y-2 bg-white drop-shadow py-3 rounded px-4"
            >
              <div className="font-semibold">{pack.name}</div>
              <div className="font-semibold">{pack.sounds_count} sounds</div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default AdminPacks;
