import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import UserLayout from "./screens/UserLayout";
import AdminLayout from "./screens/AdminLayout";
import Message from "./components/Message";
import AdminPacks from "./screens/AdminPacks";
import AdminSounds from "./screens/AdminSounds";
import AdminStatistics from "./screens/AdminStatistics";
import AdminPack from "./screens/AdminPack";
import { AiOutlineSound } from "react-icons/ai";
import { motion } from "framer-motion";
import { IoIosClose } from "react-icons/io";
import { GoMute } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { setVolume } from "./redux/settingsSlice";
import TrendingSounds from "./screens/TrendingSounds";
import NewSounds from "./screens/NewSounds";
import SoundPacks from "./screens/SoundPacks";
import Pack from "./screens/Pack";
import {
  closeNewsLetterModal,
  closePackRequestModal,
} from "./redux/modalsOpenerSlice";
import { IoMdClose } from "react-icons/io";
import { api_url, socialLinks } from "./constants";
import axios from "axios";
import { OtpInput } from "reactjs-otp-input";
import { FaCheck } from "react-icons/fa6";
import { setGlobalErrorMessage } from "./redux/globalMessageSlice";
import SearchSounds from "./screens/SearchSounds";
import Playlist from "./screens/Playlist";

function App() {
  // const dispatch = useDispatch();
  // const { pathname } = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   axios
  //     .get(api_url + "/seo/", {
  //       params: {
  //         slug: pathname,
  //       },
  //     })
  //     .then((res) => {
  //       dispatch(setUserPage(res.data));
  //     })
  //     .catch((err) => {});
  // }, [pathname]);
  const [openSettings, setOpenSettings] = useState(false);
  const settings = useSelector((state) => state.settings);
  const modalsOpener = useSelector((state) => state.modalsOpener);
  const dispatch = useDispatch();

  useEffect(() => {
    const progressBar = document.getElementById("progress-bar");
    const progressHeight = document.getElementById("progress-height");
    const progressPoint = document.getElementById("progress-point");

    function mouseMove(e) {
      const rect = progressBar.getBoundingClientRect();
      let clientY = e.clientY || e.touches[0].clientY;

      let height = rect.bottom - clientY;
      if (height < 0) height = 0;
      if (height > rect.height) height = rect.height;

      if (height < rect.height / 3) {
        document.getElementById("volume-icon").classList.add("text-secondary");
        document.getElementById("volume-icon").classList.remove("text-white");
      } else {
        document
          .getElementById("volume-icon")
          .classList.remove("text-secondary");
        document.getElementById("volume-icon").classList.add("text-white");
      }

      progressHeight.style.height = height + "px";
      const volume = Math.round((height / rect.height) * 100);
      dispatch(setVolume(volume));
    }

    function mouseUp() {
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
      document.removeEventListener("touchmove", mouseMove);
      document.removeEventListener("touchend", mouseUp);
    }

    progressPoint.addEventListener("mousedown", (e) => {
      e.preventDefault();
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
    });

    progressPoint.addEventListener("touchstart", (e) => {
      e.preventDefault();
      document.addEventListener("touchmove", mouseMove);
      document.addEventListener("touchend", mouseUp);
    });

    return () => {
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
      document.removeEventListener("touchmove", mouseMove);
      document.removeEventListener("touchend", mouseUp);
    };
  }, []);

  const [isClosingModal, setIsClosingModal] = useState(false);
  const [isEmailConfirmationSending, setIsEmailConfirmationSending] =
    useState(false);
  const [isEmailConfirmationSent, setIsEmailConfirmationSent] = useState(false);
  const [isOTPConfirmationSending, setIsOTPConfirmationSending] =
    useState(false);
  const [isSubscriptionConfirmed, setIsSubscriptionConfirmed] = useState(false);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const [isPackRequestSending, setIsPackRequestSending] = useState(false);
  const [isPackRequestSent, setIsPackRequestSent] = useState(false);
  const [isEmailProvided, setIsEmailProvided] = useState(false);

  function CloseModal() {
    setIsClosingModal(true);
    setTimeout(() => {
      dispatch(closeNewsLetterModal());
      dispatch(closePackRequestModal());
      setIsClosingModal(false);
    }, 400);

    setTimeout(() => {
      setIsEmailConfirmationSending(false);
      setIsEmailConfirmationSent(false);
      setIsOTPConfirmationSending(false);
      setIsSubscriptionConfirmed(false);
      setIsPackRequestSending(false);
      setIsPackRequestSent(false);
      setIsEmailProvided(false);
      setEmail("");
      setOtp("");
    }, 600);
  }

  function SendNewsletterEmail(e) {
    e.preventDefault();
    const form = e.target;
    const email = form.email.value;
    setIsEmailConfirmationSending(true);
    axios
      .post(api_url + "/newsletter/", { email })
      .then((res) => {
        setIsEmailConfirmationSending(false);
        setIsEmailConfirmationSent(true);
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Failed to send email"));
        setIsEmailConfirmationSending(false);
      });
  }

  function ConfirmNewsletterEmail(e) {
    e.preventDefault();
    const form = e.target;

    setIsOTPConfirmationSending(true);
    axios
      .post(api_url + "/newsletter/activate/", { email, code: otp })
      .then((res) => {
        setIsOTPConfirmationSending(false);
        setIsSubscriptionConfirmed(true);
        setIsEmailConfirmationSent(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setGlobalErrorMessage("Failed to confirm email"));
        setIsOTPConfirmationSending(false);
      });
  }

  function SendPackRequest(e) {
    e.preventDefault();
    const form = e.target;
    const description = form.desription.value.trim();
    const email = form.email.value.trim();

    const formData = new FormData();
    formData.append("description", description);

    if (email.length > 0) {
      formData.append("email", email);
      setIsEmailProvided(true);
    }

    setIsPackRequestSending(true);
    axios
      .post(api_url + "/request/packs/", formData)
      .then((res) => {
        setIsPackRequestSending(false);
        setIsPackRequestSent(true);
      })
      .catch((err) => {
        dispatch(setGlobalErrorMessage("Failed to send request"));
        setIsPackRequestSending(false);
      });
  }

  return (
    <div className="App ">
      <Routes>
        <Route path="" element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="trending" element={<TrendingSounds />} />
          <Route path="new" element={<NewSounds />} />
          <Route path="all-packs" element={<SoundPacks />} />
          <Route path="packs" element={<SoundPacks />} />
          <Route path="packs/:slug" element={<Pack />} />{" "}
          <Route path="search" element={<SearchSounds />} />
          <Route path="playlist" element={<Playlist />} />
        </Route>
        <Route path="admin" element={<AdminLayout />}>
          <Route path="packs" element={<AdminPacks />} />
          <Route path="sounds" element={<AdminSounds />} />
          <Route path="statistics" element={<AdminStatistics />} />
          <Route path="packs/:id" element={<AdminPack />} />
        </Route>
      </Routes>
      <motion.div
        initial={{ right: -8 }}
        animate={{ right: openSettings ? -40 : -8 }}
        className="right-0 fixed bottom-44 flex flex-col gap-y-2"
      >
        {socialLinks.map((link, index) => (
          <a key={index} href={link.url} target="_blank" rel="noreferrer">
            <motion.button
              key={index}
              whileHover={{ translateX: -8 }}
              className="w-10 h-10 rounded-l-md flex text-2xl text-primary items-center justify-start pl-1 bg-white drop-shadow-lg"
            >
              {link.icon}
            </motion.button>
          </a>
        ))}
      </motion.div>
      <motion.button
        initial={{ right: -56 }}
        whileHover={{ right: openSettings ? -100 : -48 }}
        duration={0.2}
        animate={{ right: openSettings ? -100 : -56 }}
        onClick={() => setOpenSettings(true)}
        className="bg-primary drop-shadow-lg text-white origin-center flex items-start h-16 uppercase font-semibold -rotate-90 px-4 py-1 rounded-t-md fixed bottom-20 "
      >
        settings
      </motion.button>
      <motion.div
        initial={{ right: -100 }}
        animate={{ right: openSettings ? 12 : -100 }}
        transition={{ duration: 0.2, delay: openSettings ? 0.2 : 0 }}
        className="fixed bottom-4  flex flex-col gap-y-4"
      >
        <button
          onClick={() => setOpenSettings(false)}
          className="w-10 h-10 bg-white relative rounded-md drop-shadow-lg"
        >
          <IoIosClose className="text-2xl text-secondary absolute-center" />
        </button>
        <div
          id="progress-bar"
          className="bg-white drop-shadow-md w-10  h-28 flex flex-col rounded-md"
        >
          <div
            id="progress-height"
            className={"mt-auto w-full relative bg-primary rounded-md"}
            style={{ height: settings.volume + "%" }}
          >
            <div
              id="progress-point"
              className="w-full -translate-y-2 py-4 cursor-pointer absolute top-0 rounded-md"
            ></div>
          </div>
          <div className="absolute bottom-4 w-fit h-fit mx-auto  left-1/2 -translate-x-1/2">
            {settings.volume === 0 ? (
              <GoMute
                id="volume-icon"
                className="text-2xl  opacity-35 pointer-events-none transition-colors ease-in-out duration-200	 text-secondary"
              />
            ) : (
              <AiOutlineSound
                id="volume-icon"
                className="text-2xl  opacity-35 pointer-events-none transition-colors ease-in-out duration-200	 text-white"
              />
            )}
          </div>
        </div>
      </motion.div>
      {(modalsOpener.newsLetterModal || modalsOpener.packRequestModal) && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isClosingModal ? 0 : 1 }}
          transition={{ duration: 0.2, delay: isClosingModal ? 0.2 : 0 }}
          onClick={CloseModal}
          className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-50"
        ></motion.div>
      )}
      <motion.div
        initial={{ translateX: "-100%" }}
        animate={{
          translateX:
            modalsOpener.newsLetterModal || modalsOpener.packRequestModal
              ? "0%"
              : "-100%",
        }}
        transition={{
          duration: 0.2,
          delay: isClosingModal ? 0 : 0.1,
        }}
        className="fixed bottom-0 top-0 px-6 left-0 w-96 sm:w-full py-16 bg-white drop-shadow-lg h-screen flex flex-col gap-y-2 z-50"
      >
        <button className="absolute top-5 right-5" onClick={CloseModal}>
          <IoMdClose className="text-2xl text-primary" />
        </button>
        {(modalsOpener.newsLetterModal && (
          <div>
            {!isSubscriptionConfirmed ? (
              <div className="flex flex-col gap-y-2">
                <h2 className="text-xl font-bold text-secondary w-fit min-w-[50%]">
                  Subscribe to Newsletter
                </h2>
                <p className="text-tertiary text-sm">
                  Subscribe to our newsletter to get notified about new and
                  trending sound effects. We send out a newsletter every week
                  and it is absolutely free.
                </p>
                <form onSubmit={SendNewsletterEmail}>
                  <input
                    disabled={
                      isEmailConfirmationSent || isEmailConfirmationSending
                    }
                    type="email"
                    placeholder="Your email address"
                    className="custom-input mt-4 disabled:opacity-50"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    disabled={
                      isEmailConfirmationSending || isEmailConfirmationSent
                    }
                    className="custom-button mt-4 disabled:opacity-50"
                  >
                    {isEmailConfirmationSent
                      ? "Email sent"
                      : isEmailConfirmationSending
                      ? "Sending..."
                      : "Subscribe"}
                  </button>
                </form>
              </div>
            ) : (
              <div className="flex flex-col gap-y-2 mt-4">
                <motion.span
                  initial={{ opacity: 0, y: -10, scale: 0.9 }}
                  animate={{ opacity: 1, y: 0, scale: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <FaCheck className="text-5xl w-fit mx-auto block text-primary" />
                </motion.span>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  className="text-tertiary text-sm"
                >
                  You have successfully subscribed to our newsletter. You will
                  now receive weekly emails with new and trending sound effects.
                </motion.p>
              </div>
            )}
            {isEmailConfirmationSent && !isSubscriptionConfirmed && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
                className="flex flex-col gap-y-2 mt-4"
              >
                <p className="text-tertiary text-sm">
                  We have sent you an email with a confirmation code. Please
                  enter the code below to confirm your subscription.
                </p>
                <form onSubmit={ConfirmNewsletterEmail}>
                  <OtpInput
                    disabled={isOTPConfirmationSending}
                    value={otp}
                    onChange={setOtp}
                    numInputs={5}
                    className="border-1 disabled:opacity-50 text-tertiary font-medium border-tertiary/50 h-10 w-10 text-center rounded flex items-center justify-center"
                    containerStyle={"grid grid-cols-5 gap-2 w-full block"}
                  />
                  <button
                    disabled={isOTPConfirmationSending}
                    className="custom-button mt-4 disabled:opacity-50"
                  >
                    {isOTPConfirmationSending ? "Confirming..." : "Confirm"}
                  </button>
                </form>
              </motion.div>
            )}
          </div>
        )) ||
          (modalsOpener.packRequestModal && (
            <div>
              {!isPackRequestSent ? (
                <div className="flex flex-col gap-y-2">
                  <h2 className="text-xl font-bold text-secondary w-fit min-w-[50%]">
                    Request Sound Effects
                  </h2>
                  <p className="text-tertiary text-sm">
                    Request sound effects that you need for your projects. We
                    will try to add them to our library as soon as possible, in
                    less than 24 hours.
                  </p>
                  <form
                    onSubmit={SendPackRequest}
                    className="gap-y-3 mt-2 flex-col flex"
                  >
                    <textarea
                      disabled={isPackRequestSending}
                      placeholder="Describe the sound effects you need"
                      className="custom-input disabled:opacity-50 h-36"
                      name="desription"
                    ></textarea>
                    <div>
                      <input
                        disabled={isPackRequestSending}
                        type="email"
                        placeholder="Your email address (optional)"
                        className="custom-input disabled:opacity-50"
                        name="email"
                      />
                      <p className="text-xs font-semibold mt-1 text-tertiary/75">
                        We will use the email address to notify you when the
                        sound effects are added.
                      </p>
                    </div>
                    <button
                      disabled={isPackRequestSending}
                      className="custom-button disabled:opacity-50"
                    >
                      {isPackRequestSending
                        ? "Sending request..."
                        : "Send request"}
                    </button>
                  </form>
                </div>
              ) : (
                <div className="flex flex-col gap-y-2 mt-4">
                  <motion.span
                    initial={{ opacity: 0, y: -10, scale: 0.9 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <FaCheck className="text-5xl w-fit mx-auto block text-primary" />
                  </motion.span>
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    className="text-tertiary text-sm"
                  >
                    Your request has been sent.{" "}
                    {isEmailProvided
                      ? "We will notify you when the sound effects are added to the library."
                      : "We will add the sound effects to the library as soon as possible."}
                  </motion.p>
                </div>
              )}
            </div>
          ))}
      </motion.div>
      <Message />
    </div>
  );
}

export default App;
