import React, { useState, useEffect } from "react";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import soundBars from "../static/images/sound-bars.svg";
import axios from "axios";
import { api_url, media_url, faqs } from "../constants";
import { useNavigate } from "react-router-dom";
import SoundEffectCard from "../components/SoundEffectCard";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { setGlobalErrorMessage } from "../redux/globalMessageSlice";
const Home = () => {
  const [sounds, setSounds] = useState([]);
  const [faqsList, setFaqsList] = useState(faqs);
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(api_url + "/sounds/suggested/")
      .then((res) => {
        const soundsData = res.data;
        soundsData.forEach((sound) => {
          sound.audioObject = new Audio(media_url + sound.audio);
        });
        setSounds(soundsData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>
          desoundfx - All the sound effects you need in a single place
        </title>
        <meta
          name="description"
          content="dedesoundfx.com offers a wide range of sound effects for your projects. You can get all the sound effects you need in a single place."
        />
      </Helmet>

      <div className="bg-background h-[calc(100vh-5rem)] flex flex-col">
        <div className="px-100 pt-24 xl:px-80 lg:px-52 md:px-20 sm:px-8">
          <p className="text-secondary text-center text-lg sm:text-base">
            desoundfx.com offers a wide range of sound effects for your
            projects.
          </p>
          <h1 className="text-center font-bold text-6xl mt-3 text-secondary leading-[4.5rem] md:text-5xl md:leading-[3.5rem] sm:text-4xl sm:leading-[3rem]">
            All the sound effects you need in a single place
          </h1>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              const search = e.target.search.value;
              if (search === "") {
                dispatch(setGlobalErrorMessage("Please enter a search term"));
                return;
              }
              navigate("/search?q=" + search);
            }}
            className="relative  mt-12 sm:flex sm:flex-col"
          >
            <input
              name="search"
              type="text"
              placeholder={"Search the sound effect by name or category"}
              className="w-full py-6 px-8 outline-none rounded-full bg-white text-tertiary sm:py-4"
            />
            <div className="absolute  top-0 right-0 h-full py-2 px-2 sm:px-0  bottom-0 sm:relative ">
              <button className="bg-primary h-full w-full rounded-full px-16 text-white flex gap-x-2 items-center sm:py-4 sm:text-enter sm:justify-center">
                <HiMiniMagnifyingGlass className="text-lg" /> Search
              </button>
            </div>
          </form>
        </div>
        <img
          src={soundBars}
          className="min-w-full mt-auto sm:w-[400%] md:w-[300%] lg:w-[200%] xl:w-[150%] "
        />
      </div>

      <div className=" px-100 py-16 xl:px-80 lg:px-52 md:px-20 sm:px-8">
        <h2 className="text-4xl font-bold text-secondary text-center">
          Suggested effects
        </h2>
        <div className="flex flex-col gap-y-4 mt-4">
          {sounds.map((sound, index) => {
            return (
              <SoundEffectCard
                sounds={sounds}
                index={index}
                key={sound.id}
                sound={sound}
                setSounds={setSounds}
              />
            );
          })}
        </div>
      </div>
      <div className="px-100 bg-background py-16 xl:px-80 lg:px-52 md:px-20 sm:px-8">
        <h2 className="text-4xl font-bold text-secondary text-center">
          Frequently Asked Questions
        </h2>
        <div className="flex flex-col gap-y-2 mt-4">
          {faqsList.map((faq) => (
            <div className="flex flex-col text-secondary">
              <button
                onClick={() => {
                  setFaqsList(
                    faqsList.map((f) => {
                      if (f.question == faq.question) {
                        return { ...f, opened: !f.opened };
                      }
                      return f;
                    })
                  );
                }}
                className="flex justify-between py-2 font-semibold text-lg"
              >
                <div className="text-left">{faq.question}</div>
                <span className="text-2xl">{faq.opened ? "-" : "+"}</span>
              </button>
              <div
                className={
                  "flex flex-col gap-y-2 scrollbar-none overflow-y-auto transition-all ease-in-out duration-500 " +
                  (faq.opened ? "max-h-[1000px]" : "max-h-0")
                }
              >
                {faq.answer.split("\n").map((line) => (
                  <p className="text-left">{line}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
