import React, { useEffect, useState } from "react";
import { RxDownload } from "react-icons/rx";
import { media_url } from "../constants";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { MdOutlineDownloadDone } from "react-icons/md";
import { IoIosPlay } from "react-icons/io";
import { MdOutlinePause } from "react-icons/md";
import { api_url } from "../constants";

const AdminPackSoundCard = ({ sound, index, pack, setSounds }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio(media_url + sound.audio));
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedSuccessfully, setDownloadedSuccessfully] = useState(false);

  function ChangeSoundPack() {
    axios
      .put(api_url + "/sounds/" + sound.id + "/", { pack: pack })
      .then((res) => {
        setSounds((prev) => {
          return prev.map((s) => {
            if (s.id === sound.id) {
              return res.data;
            }
            return s;
          });
        });
      });
  }

  return (
    <div
      key={sound.id}
      className="flex hover:scale-[100.5%] transition-transform ease-in-out duration-200 justify-between gap-y-2 bg-white drop-shadow py-3 rounded px-4"
    >
      <button
        className="pr-2"
        onClick={() => {
          if (isPlaying) {
            audio.pause();
            audio.currentTime = 0;
          } else {
            audio.play();
          }
          setIsPlaying(!isPlaying);
        }}
      >
        {isPlaying ? (
          <MdOutlinePause className="text-2xl text-primary" />
        ) : (
          <IoIosPlay className="text-2xl text-primary" />
        )}
      </button>
      <button
        onClick={ChangeSoundPack}
        className="font-semibold text-left grow block"
      >
        {sound.name}
      </button>
      <button
        className="text-primary"
        onClick={() => {
          setIsDownloading(true);
          const url = media_url + sound.audio;
          axios({
            url: url,
            method: "GET",
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", sound.name + ".mp3");
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
            setDownloadedSuccessfully(true);
          });
        }}
      >
        {downloadedSuccessfully ? (
          <MdOutlineDownloadDone className="text-xl text-primary" />
        ) : isDownloading ? (
          <TailSpin height={20} width={20} />
        ) : (
          <RxDownload className="text-xl text-primary" />
        )}
      </button>
    </div>
  );
};

export default AdminPackSoundCard;
